
/**
 * Define all the abbreviation and their explenation for the knitting terms 
 */
export const knittingTerms = [
  { abbreviation: "BO", english: "Bind Off" },
  { abbreviation: "BOR", english: "Beginning of Round" },
  { abbreviation: "BR-Dec", english: "Brioche Decrease" },
  { abbreviation: "BRK", english: "Brioche Knit" },
  { abbreviation: "BRK2tog", english: "Brioche Knit 2 Together" },
  { abbreviation: "BRK3tog", english: "Brioche Knit 3 Together" },
  { abbreviation: "BRKFB", english: "Brioche Knit Front and Back" },
  { abbreviation: "BRK-L-Sl-Dec, BrDec-L", english: "Brioche Knit Left Slipped Decrease" },
  { abbreviation: "BRK-R-Sl-Dec, BrDec-R", english: "Brioche Knit Right Slipped Decrease" },
  { abbreviation: "BRK-YO-BRK, BrInc", english: "Brioche Knit-Yarn Over-Brioche Knit" },
  { abbreviation: "BRP", english: "Brioche Purl" },
  { abbreviation: "BRP3tog", english: "Brioche Purl 3 Together" },
  { abbreviation: "BRSSSK", english: "Brioche Slip, Slip, Slip, Knit 3 Together" },
  { abbreviation: "BYO", english: "Backward Yarn Over" },
  { abbreviation: "{CS}", english: "Chain Selvedge" },
  { abbreviation: "C1", english: "Color 1" },
  { abbreviation: "CDD, RDD", english: "Central (or Raised) Double Decrease" },
  { abbreviation: "CN", english: "Cable Needle" },
  { abbreviation: "DD-L, SK2P, S1K2P", english: "Double Decrease - Left" },
  { abbreviation: "DD-R, SSKS1P", english: "Double Decrease - Right" },
  { abbreviation: "DPN", english: "Double-Pointed Needle" },
  { abbreviation: "{ES}", english: "Exposed Selvedge" },
  { abbreviation: "FD2-L", english: "Fashioned Decrease Left – Double" },
  { abbreviation: "FD2-R", english: "Fashioned Decrease Right – Double" },
  { abbreviation: "{GSS}", english: "Garter Stitch Selvedge" },
  { abbreviation: "Inc + K2tog", english: "Increase + Knit 2 Together" },
  { abbreviation: "Inc-2", english: "Increase 2" },
  { abbreviation: "Inc-6", english: "Increase 6" },
  { abbreviation: "Inv-L", english: "Invisible Increase Left" },
  { abbreviation: "Inv-Lp", english: "Invisible Increase Left-purl" },
  { abbreviation: "Inv-R", english: "Invisible Increase Right" },
  { abbreviation: "Inv-Rp", english: "Invisible Increase Right-purl" },
  { abbreviation: "K2tog", english: "Knit 2 Together" },
  { abbreviation: "K2tog-tbl", english: "Knit 2 Together through the back loops" },
  { abbreviation: "K3tog", english: "Knit 3 Together" },
  { abbreviation: "K3tog-tbl", english: "Knit 3 Together through the back loops" },
  { abbreviation: "K3YOK3", english: "K3tog-YO-K3tog" },
  { abbreviation: "KFB", english: "Knit Front & Back" },
  { abbreviation: "Knit 1-below", english: "Knit the next stitch on the left needle in the row/round below the needle" },
  { abbreviation: "Knit 1-tbl, Knit-tbl", english: "Knit 1 stitch through the back loop" },
  { abbreviation: "Knitwise", english: "Commonly used when slipping stitches" },
  { abbreviation: "KPK", english: "Knit-Purl-Knit Increase" },
  { abbreviation: "KYOK", english: "Knit-Yarnover-Knit Increase" },
  { abbreviation: "L", english: "Left" },
  { abbreviation: "LD1", english: "Left-Leaning Single Decrease" },
  { abbreviation: "LD2", english: "Left-Leaning Double Decrease" },
  { abbreviation: "LLI", english: "Left Lifted Increase" },
  { abbreviation: "LLPI", english: "Left Lifted Purl Increase" },
  { abbreviation: "LT", english: "Left Twist" },
  { abbreviation: "M1, M1L", english: "Make 1, Make 1 Left" },
  { abbreviation: "M1-BL", english: "Make 1 Backward Loop" },
  { abbreviation: "M1Lp", english: "Make 1 Left Purl" },
  { abbreviation: "M1P, M1Rp", english: "Make 1 Purl, Make 1 Right Purl" },
  { abbreviation: "M1R", english: "Make 1 Right" },
  { abbreviation: "MB", english: "Make Bobble" },
  { abbreviation: "MC", english: "Main Color" },
  { abbreviation: "QD-L", english: "Quadruple Decrease - Left" },
  { abbreviation: "QD-R", english: "Quadruple Decrease - Right" },
  { abbreviation: "P2SO", english: "Pass two stitches over" },
  { abbreviation: "P2tog", english: "Purl 2 Together" },
  { abbreviation: "P2tog-tbl", english: "Purl 2 Together Through the Back Loops" },
  { abbreviation: "P3tog", english: "Purl 3 Together" },
  { abbreviation: "PFB", english: "Purl Front & Back" },
  { abbreviation: "PKP", english: "(Purl 1, knit 1, purl 1) into the same stitch" },
  { abbreviation: "PSSO", english: "Pass the slipped stitch over" },
  { abbreviation: "Purl 1-below", english: "Purl the next stitch on the left needle in the row/round below the needle" },
  { abbreviation: "Purl 1-tbl, Purl-tbl", english: "Purl 1 stitch through the back loop" },
  { abbreviation: "Purlwise", english: "Commonly used when slipping stitches" },
  { abbreviation: "R", english: "Right" },
  { abbreviation: "RBI", english: "Right Bar Increase" },
  { abbreviation: "RD1", english: "Right-Leaning Single Decrease" },
  { abbreviation: "RD2", english: "Right-Leaning Double Decrease" },
  { abbreviation: "RDD-L", english: "Ribbed Double Decrease-Left" },
  { abbreviation: "RDD-R", english: "Ribbed Double Decrease-Right" },
  { abbreviation: "RLI", english: "Right Lifted Increase" },
  { abbreviation: "RLPI", english: "Right Lifted Purl Increase" },
  { abbreviation: "RS", english: "Right Side" },
  { abbreviation: "RT", english: "Right Twist" },
  { abbreviation: "SSK", english: "Slip, Slip, Knit 2 Together" },
  { abbreviation: "SSP", english: "Slip, Slip, Purl 2 Together" },
  { abbreviation: "{SSS}", english: "Slip-Stitch Selvedge" },
  { abbreviation: "SSSK", english: "Slip, Slip, Slip, Knit 3 Together" },
  { abbreviation: "SSSP", english: "Slip, Slip, Slip, Purl" },
  { abbreviation: "SSTWK", english: "Twisted Slip, Slip, Slip, Knit" },
  { abbreviation: "STWK", english: "Twisted Slip, Slip, Knit" },
  { abbreviation: "TD2-L", english: "Twisted Decrease Left – Double" },
  { abbreviation: "TD2-R", english: "Twisted Decrease Right – Double" },
  { abbreviation: "TS", english: "Twinned Stitch" },
  { abbreviation: "{TSSS}", english: "Twisted Slip-Stitch Selvedge" },
  { abbreviation: "{WCS}", english: "Wrapped Chain Selvedge" },
  { abbreviation: "W&T", english: "Wrap and Turn" },
  { abbreviation: "WS", english: "Wrong Side" },
  { abbreviation: "WYIB", english: "With Yarn in Back" },
  { abbreviation: "WYIF", english: "With Yarn in Front" },
  { abbreviation: "Yf-Sl1-YO", english: "Yarn Forward, Slip 1, Yarn Over" },
  { abbreviation: "Yf-Sl1-YOF", english: "Yarn Forward, Slip 1, Yarn Over to Front" },
  { abbreviation: "YO", english: "Yarn Over" }
  
];

  // { abbreviation: "", english: "", norwegian: "" },
